import { createContext, PropsWithChildren, useState } from 'react';

import { MobileNavigation } from './MobileNavigation';
import { MobileNavigationContextArguments } from './MobileNavigationProvider.types';

export const MobileNavigationContext = createContext<MobileNavigationContextArguments | undefined>(undefined);

export const MobileNavigationProvider = ({
  children,
}: PropsWithChildren) => {
  const [show, setShow] = useState(false);

  return (
    <MobileNavigationContext.Provider
      value={{
        show,
        setShow,
      }}
    >
      { children }
      <MobileNavigation
        open={show}
        onClose={() => setShow(false)}
      />
    </MobileNavigationContext.Provider>
  );
};
