import { css } from '@mui/material';
import classNames from 'classnames';
import Image, { ImageProps } from 'next/image';

import YearlyBadgeImage from '@/assets/images/yealy_badge@3x.png';

export type YearlyBadgeProps = Omit<ImageProps, 'src' | 'width' | 'src' | 'alt'>

export const YearlyBadge = (props: YearlyBadgeProps) => {
  return (
    <Image
      { ...props }
      className={classNames(
        'YearlyBadge',
        props.className
      )}
      alt="Yearly Member"
      src={YearlyBadgeImage.src}
      width={147}
      height={24}
      css={css`
        width: 147px;
        height: auto;
      `}
    />
  );
};
