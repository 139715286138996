import { css } from '@mui/material';
import classNames from 'classnames';
import { forwardRef, HTMLAttributes, Ref } from 'react';

export type EmptyButtonProps = HTMLAttributes<HTMLButtonElement>

export const EmptyButton = forwardRef((props: EmptyButtonProps, ref: Ref<HTMLButtonElement>) => {
  return (
    <button
      ref={ref}
      { ...props }
      className={classNames(
        'EmptyButton',
        props.className
      )}
      css={css`
        appearance: none;
        border: none;
        background-color: transparent;
      `}
    >
      { props.children }
    </button>
  );
});
