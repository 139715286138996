import { styled, Typography } from '@mui/material';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { Fragment, HTMLAttributes, useCallback } from 'react';

import { HEADER_NAVIGATION } from '~/src/constants/Navigation';
import { AmplitudeTaxonomyClient } from '~/src/helpers/AmplitudeTaxonomyClient';
import { useAuth } from '~/src/hooks/v2/Account/useAuth';
import { CognitoClient } from '~/src/utils/cognito';

import { useAccountDialog } from '../../business/Dialogs';
import { Logo } from '../../business/Logo';
import { Button } from '../Button/v2';
import { Icon } from '../Icon/v2';
import { Link } from '../Link';
import { useLottieLoadingOverlay } from '../LottieLoadingOverlayProvider';
import { Modal } from '../Modal';

export interface MobileNavigationProps extends HTMLAttributes<HTMLDivElement> {
  open?: boolean;
  onClose?: () => any;
}

export const MobileNavigation = (props: MobileNavigationProps) => {
  const { t }  = useTranslation('common');
  const { userId } = useAuth();
  const { asPath } = useRouter();
  const { show: showLottieLoadingOverlay } = useLottieLoadingOverlay();
  const { show: showAccountDialog } = useAccountDialog();
  const isAuthorized = Boolean(userId);

  const clickTemplates = useCallback(() => {
    AmplitudeTaxonomyClient.clickGnbTemplates();
  }, []);

  const clickPricing = useCallback(() => {
    AmplitudeTaxonomyClient.clickGnbPricing();
  }, []);

  const clickBlog = useCallback(() => {
    AmplitudeTaxonomyClient.clickGnbBlog();
  }, []);

  const clickLogoIdeas = useCallback(() => {
    AmplitudeTaxonomyClient.clickGnbLogoIdeas();
  }, []);

  const clickMyWork = useCallback(() => {
    AmplitudeTaxonomyClient.clickMyWorkButton();
  }, []);

  const clickAccountSetting = useCallback(() => {
    AmplitudeTaxonomyClient.clickAccountSetting();
  }, []);

  const onSignOut = useCallback(() => {
    showLottieLoadingOverlay();
    CognitoClient.signOut();
  }, [
    showLottieLoadingOverlay,
  ]);

  const handleClickNavigation = useCallback((key: string) => {
    switch (key) {
    case 'templates':
      clickTemplates();
      break;

    case 'pricing':
      clickPricing();
      break;

    case 'blog':
      clickBlog();
      break;

    case 'logo-ideas':
      clickLogoIdeas();
      break;
    }
  }, [
    clickTemplates,
    clickPricing,
    clickBlog,
    clickLogoIdeas,
  ]);

  const clickLoginButton = useCallback(() => {
    showAccountDialog(asPath);
    AmplitudeTaxonomyClient.clickLoginButton();
  }, [
    asPath,
    showAccountDialog,
  ]);

  const clickSignupButton = useCallback(() => {
    // memo: home 화면일 때는 redirect uri를 남기지 않도록 함.
    const isHome = asPath === '/';
    showAccountDialog(isHome ? undefined : asPath);
    AmplitudeTaxonomyClient.clickSignUpButton();
  }, [
    asPath,
    showAccountDialog,
  ]);

  return (
    <Modal
      closeAfterTransition
      open={props.open ?? true}
      onClose={props.onClose}
      slotProps={{
        root: {
          onClick: props.onClose,
        },
      }}
    >
      <MobileNavigationDrawer
        className={classNames(
          'MobileNavigation',
          props.className
        )}
        onClick={e => e.stopPropagation()}
      >
        <MobileNavigationHeader>
          <Logo
            href="/"
            width={70}
          />
          <CloseButton
            type="button"
            onClick={props.onClose}
          >
            <Icon
              width={24}
              height={24}
            >
              ic_close_x_black
            </Icon>
          </CloseButton>
        </MobileNavigationHeader>
        <MobileNavigationList
          className="MobileNavigation__List"
        >
          {HEADER_NAVIGATION.map(({ key, path, exact }) => {
            return (
              <MobileNavigationItem
                key={key}
                className="MobileNavigation__Item"
                onClick={props.onClose}
              >
                <LinkContainer
                  href={path}
                  exact={exact}
                  onClick={() => handleClickNavigation(key)}
                >
                  <Typography variant="body5">
                    {t(`header.${key}`)}
                  </Typography>
                </LinkContainer>
              </MobileNavigationItem>
            );
          })}
        </MobileNavigationList>

        {
          isAuthorized
            && (
              <Fragment>
                <MobileNavigationList>
                  <MobileNavigationItem
                    className="MobileNavigation__Item"
                    onClick={props.onClose}
                  >
                    <LinkContainer
                      href="/my-work"
                      onClick={clickMyWork}
                    >
                      <NavigationIcon
                        width={24}
                        height={24}
                      >
                        ic_mywork
                      </NavigationIcon>
                      <Typography variant="body5">
                        {t('header.my-work')}
                      </Typography>
                    </LinkContainer>
                  </MobileNavigationItem>
                  <MobileNavigationItem
                    className="MobileNavigation__Item"
                    onClick={props.onClose}
                  >
                    <LinkContainer
                      href="/my-page"
                      onClick={clickAccountSetting}
                    >
                      <NavigationIcon
                        width={24}
                        height={24}
                      >
                        ic_setting
                      </NavigationIcon>
                      <Typography variant="body5">
                        {t('header.setting')}
                      </Typography>
                    </LinkContainer>
                    <MobileNavigationNestedList>
                      <MobileNavigationItem>
                        <LinkContainer
                          href="/my-page/account"
                          exact
                          onClick={clickAccountSetting}
                        >
                          <Typography variant="body6">
                            {t('header.my-account')}
                          </Typography>
                        </LinkContainer>
                      </MobileNavigationItem>
                      <MobileNavigationItem>
                        <LinkContainer
                          href="/my-page/payments"
                          exact
                          onClick={clickAccountSetting}
                        >
                          <Typography variant="body6">
                            {t('header.billing')}
                          </Typography>
                        </LinkContainer>
                      </MobileNavigationItem>
                    </MobileNavigationNestedList>
                  </MobileNavigationItem>
                </MobileNavigationList>
                <MobileNavigationList>
                  <MobileNavigationItem
                    className="MobileNavigation__Item"
                    onClick={onSignOut}
                  >
                    <NavigationIcon
                      width={24}
                      height={24}
                    >
                      ic_signout
                    </NavigationIcon>
                    <Typography variant="body5">
                      {t('header.sign-out')}
                    </Typography>
                  </MobileNavigationItem>
                </MobileNavigationList>
              </Fragment>
            )
        }
        {
          !isAuthorized
            && (
              <Actions>
                <ActionItem
                  className="MobileNavigation__Item"
                >
                  <FullSizeButton
                    variant="outlined"
                    size="medium"
                    onClick={clickLoginButton}
                  >
                    { t('header.login') }
                  </FullSizeButton>
                </ActionItem>
                <ActionItem
                  className="MobileNavigation__Item"
                >
                  <FullSizeButton
                    size="medium"
                    color="gradient"
                    onClick={clickSignupButton}
                  >
                    { t('header.sign-up') }
                  </FullSizeButton>
                </ActionItem>
              </Actions>
            )
        }
      </MobileNavigationDrawer>
    </Modal>
  );
};

const MobileNavigationDrawer = styled('div')`
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  position: absolute;
  right: 0;
  top: 0;
  width: 40vw;
  min-width: 260px;
  height: 100dvh;
  overflow-y: scroll;
`;

const MobileNavigationHeader = styled('div')`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 72px;
  padding: 0 12px 0 24px;
`;

const MobileNavigationList = styled('ul')`
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: left;
  padding: 32px 24px;
  border-bottom: 1px solid #00000026;
  &:last-of-type {
    border-bottom: none;
  }
`;

const MobileNavigationNestedList = styled('ul')`
  width: 100%;
  margin-top: 24px;
  padding-left: 36px;
`;

const MobileNavigationItem = styled('li')`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  row-gap: 8px;
  margin-bottom: 24px;
  &:last-of-type {
    margin-bottom: 0;
  }
`;

const NavigationIcon = styled(Icon)`
  width: 24px;
  height: 24px;
  margin-right: 15px;
`;

const CloseButton = styled('button')`
  appearance: none;
  width: 40px;
  height: 40px;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: pointer;
`;

const Actions = styled('div')`
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 24px;
`;

const ActionItem = styled('div')`
  margin-bottom: 8px;
  &:last-of-type {
    margin-bottom: 0;
  }
`;

export const LinkContainer = styled(Link)`
  display: flex;
  align-items: center;
  /* padding: 30px 0; */
  color: ${(props) => props.theme.app.colors['neutral-color-04']};
  text-decoration: none;
  &.Link--Active {
    color: ${(props) => props.theme.app.colors['primary-color-01']};
    path, rect, line {
      stroke: ${(props) => props.theme.app.colors['primary-color-01']} !important;
    }
  }
  &:hover {
    color: ${(props) => props.theme.app.colors['primary-color-02']};
    path, rect, line {
      stroke: ${(props) => props.theme.app.colors['primary-color-02']} !important;
    }
  }
`;

const FullSizeButton = styled(Button)`
  width: 100%;
`;
