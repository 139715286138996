
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useCallback, useState } from 'react';

import { ContextMenuItem } from '~/src/components/app/ContextMenuItem';
import { LoadingOverlay } from '~/src/components/app/LoadingOverlay';
import { AmplitudeTaxonomyClient } from '~/src/helpers/AmplitudeTaxonomyClient';
import { CognitoClient } from '~/src/utils/cognito';

export interface AvatarMenuProps {
  onClickItem?: () => any;
}

export const AvatarMenu = ({ onClickItem }: AvatarMenuProps) => {
  const router = useRouter();
  const { t } = useTranslation('common');
  const [showLoading, setShowLoading] = useState(false);

  const redirectMyPage = useCallback(() => {
    router.push('/my-page');
    AmplitudeTaxonomyClient.clickAccountSetting();

    onClickItem && onClickItem();
  }, [router, onClickItem]);

  const onSignOut = useCallback(() => {
    setShowLoading(true);
    CognitoClient.signOut();
  }, []);

  return (
    <>
      <LoadingOverlay
        active={showLoading}
      />
      <ContextMenuItem
        className="AvatarMenu__Item"
        icon="ic_setting"
        onClick={redirectMyPage}
      >
        { t('common:header.my-account') }
      </ContextMenuItem>
      <ContextMenuItem
        className="AvatarMenu__Item"
        icon="ic_signout"
        onClick={onSignOut}
      >
        { t('common:header.sign-out') }
      </ContextMenuItem>
    </>
  );
};
