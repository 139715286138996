import { css, useTheme  } from '@mui/material';

import { Link } from '~/src/components/app/Link';

import { NavigationLinkProps } from './NavigationLink.types';

export const NavigationLink = ({
  children,
  href,
  exact,
  target,
  onClick,
}: NavigationLinkProps) => {
  const theme = useTheme();

  return (
    <Link
      target={target}
      href={href}
      exact={exact}
      css={css`
        ${theme.app.typography.body5}
        color: ${theme.app.colors['neutral-color-04']};
        text-decoration: none;
        &.Link--Active {
          color: ${theme.app.colors['primary-color-01']};
        }
      `}
      onClick={onClick}
    >
      { children }
    </Link>
  );
};
