import { css, GlobalStyles, useTheme } from '@mui/material';
import classNames from 'classnames';
import { useCallback } from 'react';

import { HEADER_NAVIGATION } from '~/src/constants/Navigation';
import { AmplitudeTaxonomyClient } from '~/src/helpers/AmplitudeTaxonomyClient';

import { LayoutContext } from '../../layouts/Normal/LayoutProvider';
import { Logo } from '../Logo';
import { AuthenticationColumn } from './components/AuthenticationColumn';
import { HeaderColumn } from './components/HeaderColumn';
import { NavigationMenu } from './components/NavigationMenu';
import { HeaderProps } from './Header.types';

const HEADER_HEIGHT = 72;

const globalStyles = (
  <GlobalStyles
    styles={css`
      :root {
        --layout-header-height: ${HEADER_HEIGHT}px;
        --layout-desktop-header-height: ${HEADER_HEIGHT}px;
      }
    `}
  />
);

export const Header = ({
  style,
  className,
}: HeaderProps) => {
  const theme = useTheme();
  const { hideNavigation } = LayoutContext();

  const clickBlog = useCallback(() => {
    AmplitudeTaxonomyClient.clickGnbBlog();
  }, []);

  const clickTemplates = useCallback(() => {
    AmplitudeTaxonomyClient.clickGnbTemplates();
  }, []);

  const clickPricing = useCallback(() => {
    AmplitudeTaxonomyClient.clickGnbPricing();
  }, []);

  const clickLogoIdeas = useCallback(() => {
    AmplitudeTaxonomyClient.clickGnbLogoIdeas();
  }, []);

  const handleClickNavigation = useCallback((key: string) => {
    switch (key) {
    case 'blog':
      clickBlog();
      break;

    case 'templates':
      clickTemplates();
      break;

    case 'pricing':
      clickPricing();
      break;

    case 'logo-ideas':
      clickLogoIdeas();
      break;
    }
  }, [
    clickBlog,
    clickTemplates,
    clickPricing,
    clickLogoIdeas,
  ]);

  return (
    <header
      className={classNames(
        'Header',
        className
      )}
      style={style}
      css={css`
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: ${HEADER_HEIGHT}px;
        background-color: ${theme.app.colors['neutral-color-10']};
        padding: 0 46px;
        border-bottom: 1px solid ${theme.app.colors['neutral-color-08']};
      `}
    >
      {globalStyles}
      <HeaderColumn>
        <Logo
          href="/"
        />
      </HeaderColumn>
      {hideNavigation === false && (
        <HeaderColumn
          className="Header_NavigationContainer"
          as={'nav'}
        >
          <NavigationMenu
            navigation={HEADER_NAVIGATION}
            onClickNavigation={({ key }) => handleClickNavigation(key)}
          />
        </HeaderColumn>
      )}
      <AuthenticationColumn />
    </header>
  );
};
