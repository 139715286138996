import { css } from '@emotion/react';
import classNames from 'classnames';
import { PropsWithChildren, useEffect } from 'react';

import { ComponentProps } from '~/src/types/common';

import { MobileNavigationProvider } from '../../app/MobileNavigation';
import { Footer } from '../../business/Footer';
import { LayoutContext } from './LayoutProvider';
import { ResponsibleHeader } from './ResponsibleHeader';

interface LayoutProps extends PropsWithChildren, ComponentProps {
  hideNavigation?: boolean;
  hideFooter?: boolean;
}

export const Layout = ({ children, hideNavigation = false, hideFooter = false, ...props }: LayoutProps) => {
  const { setHideNavigation } = LayoutContext();

  useEffect(() => {
    setHideNavigation(hideNavigation);
  }, [hideNavigation, setHideNavigation]);

  return (
    <MobileNavigationProvider>
      <div
        className={classNames(
          'NormalLayout',
          props.className
        )}
        css={css`
          position: relative;
          width: 100%;
          min-height: 100vh;
          -webkit-user-select:none;
          -moz-user-select:none;
          -ms-user-select:none;
          user-select:none; 
        `}
        onContextMenu={e => { e.preventDefault();}}
        onDragStart={e => {e.preventDefault();}}
      >
        <div
          className="NormalLayout__Container"
          css={css`
            width: 100%;
            transition: transform .3s;
          `}
        >
          <ResponsibleHeader />
          <main
            className="NormalLayout__Main"
            css={css`
              width: 100%;
              min-height: 100vh;
            `}
          >
            { children }
          </main>
          { hideFooter === false && <Footer /> }
        </div>
      </div>
    </MobileNavigationProvider>
  );
};
