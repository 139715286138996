import { css } from '@emotion/react';
import { Typography } from '@mui/material';
import { useTranslation } from 'next-i18next';
import { CSSProperties, useMemo } from 'react';

import { useMediaQuery } from '~/src/hooks/v1/Common/useMediaQuery';

import { NavigationLink } from '../NavigationLink';
import { NavigationMenuProps } from './NavigationMenu.types';

export const NavigationMenu = ({
  navigation,
  onClickNavigation,
}: NavigationMenuProps) => {
  const { t } = useTranslation('common');
  const desktop = useMediaQuery('desktop');

  const sideSpace = useMemo(() => {
    if (desktop.down.is) return 12;

    return 24;
  }, [
    desktop,
  ]);

  return (
    <ul
      className="Header__Navigation"
      style={{
        '--navigation-side-space': `${sideSpace}px`,
      } as CSSProperties}
      css={css`
        margin: auto;
        display: flex;
        align-items: center;
        margin-left: calc(var(--navigation-side-space) * -1);
        margin-right: calc(var(--navigation-side-space) * -1);
      `}
    >
      {
        navigation.map((item) => {
          const { key, path, exact } = item;

          return (
            <li
              key={key}
              className="Header__NavigationItem"
              css={css`
                padding-left: var(--navigation-side-space);
                padding-right: var(--navigation-side-space);
              `}
            >
              <NavigationLink
                href={path}
                exact={exact}
                onClick={() => onClickNavigation?.(item)}
              >
                <Typography
                  variant="body5"
                >
                  { t(`common:header.${key}`) }
                </Typography>
              </NavigationLink>
            </li>
          );
        })
      }
    </ul>
  );
};
