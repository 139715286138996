import { css, Typography, useTheme } from '@mui/material';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import { useCallback } from 'react';

import { Icon } from '~/src/components/app/Icon/v2';
import { AmplitudeTaxonomyClient } from '~/src/helpers/AmplitudeTaxonomyClient';

export const MyWorkLink = () => {
  const { t } = useTranslation('common');
  const theme = useTheme();

  const clickMyWork = useCallback(() => {
    AmplitudeTaxonomyClient.clickMyWorkButton();
  }, []);

  return (
    <Link
      href="/my-work"
      role="link"
      data-href="/my-work"
      onClick={clickMyWork}
      css={css`
        display: flex;
        align-items: center;
        justify-content: center;
        height: 48px;
        margin-right: 6px;
        padding: 0 26px;
        border: 1px solid rgb(242, 242, 242);
        border-radius: 100px;
        text-decoration: none;
        &:hover {
          .MyWorkLink_Text {
            color: ${theme.app.colors['primary-color-01']};
          }
          path,
          rect,
          line {
            stroke: ${theme.app.colors['primary-color-01']} !important;
          }
        }
      `}
    >
      <Icon
        width={20}
        height={16}
        stroke={theme.app.colors['neutral-color-04']}
        css={css`
          margin-right: 8px;
        `}
      >
        ic_mywork
      </Icon>
      <Typography
        variant="button5"
        className="MyWorkLink_Text"
        css={css`
          color: ${theme.app.colors['neutral-color-04']};
        `}
      >
        { t('common:header.my-work') }
      </Typography>
    </Link>
  );
};
