import { css, Menu, useTheme  } from '@mui/material';
import { uniqueId } from 'lodash';
import { MouseEventHandler, ReactNode, useState } from 'react';

import { ComponentProps } from '~/src/types/common';


export interface ContextMenuItemProps extends ComponentProps {
  icon?: string;
  children?: ReactNode;
  onClick?: MouseEventHandler<HTMLLIElement>;
}

export interface ContextMenuProps {
  children: ReactNode;
  open?: boolean;
  menu?: ReactNode;
  onClick?: MouseEventHandler<HTMLDivElement>;
  onOpen?: () => any;
  onClose?: () => void;
}

export const ContextMenu = (props: ContextMenuProps) => {
  const theme = useTheme();
  const id = uniqueId();
  const [target, setTarget] = useState<HTMLDivElement>();
  const menuId = `menu-${id}`;
  const open = props.open ?? false;

  return (
    <>
      <div
        ref={(element) => {
          if (!element) return;

          setTarget(element);
        }}
        aria-controls={open ? menuId : undefined}
        onClickCapture={props.onOpen}
        css={css`
          display: inline-block;
          cursor: pointer;
          &:disabled {
            cursor: default;
          }
        `}
      >
        { props.children }
      </div>
      <Menu
        id={menuId}
        anchorEl={target}
        open={open}
        onClose={props.onClose}
        onClick={props.onClick}
        css={css`
          .MuiPaper-root {
            border-radius: 10px;
            box-shadow: none;
            border: 1px solid ${theme.app.colors['neutral-color-07']};
            .MuiList-root {
              padding-top: 10px;
              padding-bottom: 10px;
            }
          }
        `}
      >
        { props.menu }
      </Menu>
    </>
  );
};
