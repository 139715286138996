
import { PropsWithChildren } from 'react';

import { ComponentProps } from '~/src/types/common';

import { Layout } from './Layout';
import { LayoutProvider } from './LayoutProvider';

interface NormalLayoutProps extends PropsWithChildren, ComponentProps {
  hideNavigation?: boolean;
  hideFooter?: boolean;
}

/**
 * 모든 페이지에 공용으로 사용하는 Layout
 * @param hideNavigation Navigation을 숨기기 위해 사용함
 * @param hideFooter Footer를 숨기기 위해 사용함
 */
export const NormalLayout = ({ children, hideNavigation = false, hideFooter = false, ...props }: NormalLayoutProps) => {
  return (
    <LayoutProvider>
      <Layout
        hideNavigation={hideNavigation}
        hideFooter={hideFooter}
        { ...props }
      >
        { children }
      </Layout>
    </LayoutProvider>
  );
};
