import { css } from '@emotion/react';
import { Modal as MuiModal, ModalProps as MuiModalProps } from '@mui/material';
import classNames from 'classnames';
import { ReactNode } from 'react';

export interface ModalProps extends Omit<MuiModalProps, 'children'> {
  children?: ReactNode;
}

export const Modal = (props: MuiModalProps) => {
  return (
    <MuiModal
      { ...props }
      className={classNames(
        'Modal',
        props.className
      )}
      style={props.style}
      closeAfterTransition={props.closeAfterTransition ?? true}
    >
      <div
        className="Modal__Body"
        css={css`
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 100%;
          height: 100%;
          overflow: auto;
          padding: 32px 16px;
        `}
      >
        <div
          className="Modal__Content"
          css={css`
            margin: auto;
          `}
        >
          { props.children }
        </div>
      </div>
    </MuiModal>
  );
};
