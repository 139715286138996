import { css } from '@emotion/react';
import { clsx } from 'clsx';

import { HeaderColumnProps } from './HeaderColumn.types';

export const HeaderColumn = ({
  as = 'div',
  className,
  style,
  children,
}: HeaderColumnProps) => {
  const Component = as;

  return (
    <Component
      className={clsx(
        'HeaderColumn',
        className
      )}
      style={style}
      css={css`
        display: flex;
        align-items: center;
        height: 100%;
      `}
    >
      { children }
    </Component>
  );
};

