import constate from 'constate';
import { useState } from 'react';

const useLayoutContext = () => {
  const [hideNavigation, setHideNavigation] = useState<boolean>(false);

  return { hideNavigation, setHideNavigation };
};

export const [LayoutProvider, LayoutContext] = constate(useLayoutContext);
