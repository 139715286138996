import { css, GlobalStyles, useTheme } from '@mui/material';
import { clsx } from 'clsx';

import { Logo } from '../../business/Logo';
import { EmptyButton } from '../EmptyButton';
import { Icon } from '../Icon/v2';
import { useMobileNavigationContext } from '../MobileNavigation';
import { MobileHeaderProps } from './MobileHeader.types';

const HEADER_HEIGHT = 72;

export const MobileHeader = ({
  className,
  style,
}: MobileHeaderProps) => {
  const theme = useTheme();
  const { setShow } = useMobileNavigationContext();

  return (
    <header
      className={clsx(
        'MobileHeader',
        className
      )}
      style={style}
      css={css`
          z-index: 1;
          width: 100%;
          height: ${HEADER_HEIGHT}px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-wrap: wrap;
          width: 100%;
          padding: 20px 24px;
          box-shadow: 0 1px 0 1px ${theme.app.colors['neutral-color-08']};;
          background-color: ${theme.app.colors['neutral-color-10']};
        `}
    >
      <GlobalStyles
        styles={{
          '--layout-header-height': `${HEADER_HEIGHT}px`,
          '--layout-mobile-header-height': `${HEADER_HEIGHT}px`,
        }}
      />
      <div
        className="MobileHeader__LogoContainer"
        css={css`
          display: flex;
          align-items: center;
          height: 100%;
        `}
      >
        <Logo
          href="/"
        />
      </div>
      <div
        className="MobileHeader__NavigationContainer"
      >
        <EmptyButton
          className="MobileHeader__NavigationButton"
          aria-label="Navigation Toggle Button"
          onClick={() => setShow(true)}
        >
          <Icon
            width={36}
            height={36}
          >
            ic_navBar_hamburger
          </Icon>
        </EmptyButton>
      </div>
    </header>
  );
};
