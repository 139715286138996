import { useContext } from 'react';

import { MobileNavigationContext } from './MobileNavigationProvider';

export const useMobileNavigationContext = () => {
  const context = useContext(MobileNavigationContext);

  if (context === undefined) throw new Error('MobileNavigationContext is undefined.');

  return {
    ...context,
  };
};
