import { css, MenuItem, MenuItemProps, Typography } from '@mui/material';
import classNames from 'classnames';

import { Icon } from '../Icon/v2';

export interface ContextMenuItemProps extends MenuItemProps {
  icon?: string;
}

export const ContextMenuItem = ({
  icon,
  ...others
}: ContextMenuItemProps) => {
  return (
    <MenuItem
      className={classNames(
        others.className
      )}
      style={others.style}
      css={css`
        display: flex;
        align-items: center;
        position: relative;
        color: var(--neutral-color-03);
        height: 42px;
        padding: 0 20px;
      `}
      onClick={others.onClick}
    >
      <div
        css={css`
          width: 24px;
          height: 24px;
          margin-right: 8px;
        `}
      >
        {
          icon &&
            <Icon
              width={24}
              height={24}
            >
              { icon }
            </Icon>
        }
      </div>
      <Typography variant="body6">{ others.children }</Typography>
    </MenuItem>
  );
};
