import { css } from '@emotion/react';
import { styled, Typography, useTheme  } from '@mui/material';
import { useTranslation } from 'next-i18next';

import { AmplitudeTaxonomyClient } from '~/src/helpers/AmplitudeTaxonomyClient';
import { useMediaQuery } from '~/src/hooks/v1/Common/useMediaQuery';

import { Link } from '../../app/Link';

export const Footer = () => {
  const { t } = useTranslation('common');
  const theme = useTheme();
  const laptop = useMediaQuery('laptop');

  const clickPrivacy = () => {
    AmplitudeTaxonomyClient.clickPrivacyPolicy();
  };

  const clickTerms = () => {
    AmplitudeTaxonomyClient.clickTermsOfService();
  };

  return (
    <footer
      className="Footer"
      css={css`
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: #000000;
      `}
    >
      <div
        className="Footer__Container"
        css={css`
          display: flex;
          align-items: center;
          justify-content: space-between;
          max-width: 1136px;
          width: 100%;
          margin: auto;
          padding: 36px 30px;
          ${laptop.down.mq} {
            flex-direction: column;
            row-gap: 35px;
            padding: 30px 24px;
          }
        `}
      >
        <div
          className="Footer__Left"
          css={css`
            ${laptop.down.mq} {
              text-align: center;
            }
          `}
        >
          <Typography
            variant="body9"
            css={css`
              color: ${theme.app.colors['neutral-color-10']};
              margin-bottom: 5px;
            `}
          >
            { t('common:footer.copyright-from') }
          </Typography>
          <Typography
            variant="body8"
            css={css`
              color: ${theme.app.colors['neutral-color-05']};
            `}
          >
            <LinkContainer
              href={'/documents/privacy'}
              onClick={clickPrivacy}
            >
              { t('common:footer.privacy') }
            </LinkContainer>
            &nbsp;/&nbsp;
            <LinkContainer
              href={'/documents/service'}
              onClick={clickTerms}
            >
              { t('common:footer.terms') }
            </LinkContainer>
          </Typography>
        </div>
      </div>
    </footer>
  );
};

const LinkContainer = styled(Link)`
  color: inherit;
  text-decoration: none;
`;
