import { HeaderNavigationItem } from './index.types';

export const HEADER_NAVIGATION: HeaderNavigationItem[] = [
  {
    key: 'home',
    path: '/',
    exact: true,
  },
  {
    key: 'logo-preview',
    path: '/logo-preview',
  },
  {
    key: 'pricing',
    path: '/pricing',
  },
  {
    key: 'blog',
    path: process.env.NEXT_PUBLIC_BLOG_URL || '',
  },
  {
    key: 'logo-ideas',
    path: '/logo-ideas',
  },
];
